<template>
  <!--begin::Azure-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header card-header-tabs-line flex-wrap">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">Microsoft Azure</h3>
      </div>
      <div class="card-toolbar">
        <b-nav tabs class="nav nav-tabs nav-bold nav-tabs-line">
          <b-nav-item to="#auth" :active="$route.hash === '#auth' || $route.hash === ''">
            {{ $t("MANAGEMENT.AUTHORIZATION_TITLE") }}
          </b-nav-item>
          <b-nav-item to="#sync" :active="$route.hash === '#sync'"
                      @click="onSyncPage()">
            {{ $t("MANAGEMENT.MAPPING_MEET_ROOMS_TITLE") }}
          </b-nav-item>
          <b-nav-item to="#logs" :active="$route.hash === '#logs'"
                      @click="onLogsPage()">
            {{ $t("MANAGEMENT.LOGS_TITLE") }}
          </b-nav-item>
        </b-nav>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <div class="tab-content">
        <div :class="['tab-pane', { 'active': $route.hash === '#auth' || $route.hash === '' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-12 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.AUTHORIZATION_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">
                {{ $t("MANAGEMENT.AZURE_AUTHORIZATION_DESC") }}
              </span>
            </h5>
          </div>
          <div class="form-group row align-items-center mb-10">
            <label class="col-12 col-form-label font-weight-bolder pb-0 mb-0">{{ $t("TITLE.STATUS_TITLE") }}</label>
            <label class="col-12 col-form-label pt-0 mt-0">
              {{ azureSyncStatus.actively ? $t("MANAGEMENT.SYNC_ENABLED_TITLE") : $t("MANAGEMENT.SYNC_DISABLED_TITLE") }}
            </label>
            <div class="col-12">
              <b-button v-if="isCanEdit"
                  @click="onSync"
                  class="btn font-weight-bolder font-size-sm"
                  :class="azureSyncStatus.actively ? 'bg-light-danger text-danger' : 'bg-success text-light'">
                {{ azureSyncStatus.actively ? $t("MANAGEMENT.DISABLED_SYNC_BUTTON") : $t("MANAGEMENT.ENABLED_SYNC_BUTTON") }}
              </b-button>
            </div>
          </div>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#sync' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-12 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.MAPPING_MEET_ROOMS_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">
                {{ $t("MANAGEMENT.MAPPING_MEET_ROOMS_DESC") }}
              </span>
            </h5>
          </div>
          <!--begin::Dummy-->
          <template v-if="azureMappingMeetingRooms.length === 0">
            <div class="row">
              <div class="col-md-6 alert alert-custom alert-light-warning fade show ml-4 my-5" role="alert">
                <div class="alert-icon"><Warning/></div>
                <div class="alert-text text-secondary">{{ $t("MANAGEMENT.MAPPING_MEET_ROOMS_NOT_YET") }}</div>
              </div>
            </div>
          </template>
          <!--end::Dummy-->
          <template v-if="azureMappingMeetingRooms.length > 0">
            <div class="table-responsive">
              <b-table
                  id="azure-sync-table"
                  class="table-vertical-center"
                  show-empty
                  :items="azureMappingMeetingRooms"
                  :fields="fields">
                <template #empty="scope">
                  <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                </template>
                <template #head()="data">
                  <span class="pl-0 text-muted font-weight-bolder">{{ data.label }}</span>
                </template>
                <template #cell(meeting_room)="data">
                  <p class="azure-sync-meeting-room mt-5">{{ data.item.name }}</p>
                </template>
                <template #cell(user)="data">
                  <b-form-select
                      size="sm"
                      :disabled="!isCanEdit"
                      :value="setSyncUser(data.item)"
                      :options="azureMappingCalendarUsers"
                      value-field="id"
                      text-field="name"
                      disabled-field="isUse"
                      class="azure-sync-select"
                      @change="onUserChange(data.item, $event)">
                    <template #first>
                      <b-form-select-option :value="null">{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col-12">
                <b-button v-if="isCanEdit"
                    @click="onSaveSync"
                    class="btn bg-success text-light font-weight-bolder font-size-sm">
                  {{ $t("MANAGEMENT.SAVE_BUTTON") }}
                </b-button>
              </div>
            </div>
          </template>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#logs' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-12 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.LOGS_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">
                {{ $t("MANAGEMENT.AZURE_LOGS_DESC") }}
              </span>
            </h5>
          </div>
          <div class="row align-items-center mt-5">
            <div class="col-lg-12 col-xl-12">
              <div class="row align-items-center">
                <div class="col-md-3 my-2 my-md-0">
                  <label class="mr-3 d-none d-md-block">{{ $t("MANAGEMENT.PERIOD_TITLE") }}</label>
                  <treeselect v-model="filter_period"
                              :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                              :multiple="false"
                              :normalizer="normalizer"
                              :clearable="false"
                              :limit="1"
                              :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                              :options="periodList">
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                  </treeselect>
                </div>
              </div>
              <!--begin::Table-->
              <div class="table-responsive">
                <b-table
                    id="azure-logs-table"
                    class="table-vertical-center"
                    show-empty
                    :items="azureLogs.items"
                    :fields="logsFields"
                    :per-page="0"
                    :current-page="azureLogs.pagination.page">
                  <template #empty="scope">
                    <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                  </template>
                  <template #head()="data">
                    <span class="pl-0 text-muted font-weight-bolder">{{ data.label }}</span>
                  </template>
                  <template #cell(dateTime)="data">
                    <p class="azure-logs-date">{{ data.item.date }}</p>
                  </template>
                  <template #cell(message)="data">
                    <p class="azure-logs-message">{{ data.item.message }}</p>
                  </template>
                </b-table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--begin: Pagination-->
          <Pagination
              name="amenities-table"
              :pagination="azureLogs.pagination"
              @changePageLimit="changePageLimit">
          </Pagination>
          <!--end: Pagination-->
        </div>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Edit Period Date Modal-->
    <EditPeriodDateModal
        @changePeriodDate="changePeriodDate"/>
    <!--end::Edit Period Date Modal-->
  </div>
  <!--end::Azure-->
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_AZURE_AUTH, GET_AZURE_CHECK_SYNC,
  GET_AZURE_MAPPING_DATA,
  GET_AZURE_SYNC_STATUS,
  POST_AZURE_MAPPING_DATA,
  PUT_AZURE_CANCEL_SYNC,
  GET_AZURE_LOGS
} from "@/core/services/store/management/integrations.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

import Warning from "../../../layout/svg/Warning";
import Pagination from "../../../layout/pagination/Pagination";
import EditPeriodDateModal from "../../../layout/modal/EditPeriodDateModal";

export default {
  name: "Azure",
  components: {
    Warning,
    Pagination,
    EditPeriodDateModal
  },
  mounted() {
    this.$store.dispatch(GET_AZURE_SYNC_STATUS).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Microsoft Azure" }]);
      this.isLoading = true
    });
    this.$store.dispatch(GET_AZURE_MAPPING_DATA);
    this.$store.dispatch(GET_AZURE_LOGS, this.filters);
  },
  created() {
    if (this.$route.query.code) {
      this.$store.dispatch(GET_AZURE_CHECK_SYNC, this.$route.query)
          .then(() => {
            this.$store.dispatch(GET_AZURE_SYNC_STATUS);
            this.$store.dispatch(GET_AZURE_MAPPING_DATA);
          });
    }
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'meeting_room', label: this.$t("MANAGEMENT.MEET_ROOM_TABLE_TITLE") },
        { key: 'user', label: this.$t("MANAGEMENT.MIC_AZ_ACCOUNT_TABLE_TITLE") }
      ],
      addedMappings: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      filter_period: 1,
      filters: {
        from: moment(new Date()).add(-6, 'days').format('DD.MM.YYYY'),
        to: moment(new Date()).format('DD.MM.YYYY'),
      },
      logsFields: [
        { key: 'dateTime', label: this.$t("MANAGEMENT.DATE_TIME_TABLE_TITLE") },
        { key: 'message', label: this.$t("MANAGEMENT.DESC_TABLE_TITLE") }
      ]
    }
  },
  watch: {
    filter_period(val) {
      if (val === 1) {
        this.filters.from = moment(new Date()).add(-6, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 2) {
        this.filters.from = moment(new Date()).add(-14, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 3) {
        this.filters.from = moment(new Date()).add(-29, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 4) {
        this.filters.from = moment(new Date()).startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 5) {
        this.filters.from = moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
      } else if (val === 6) {
        this.$bvModal.show('modal-edit-period-date');
      }
      if (val !== undefined) {
        let data = { ...this.filters, ...{ page: '1', limit: '10' } };
        this.$store.dispatch(GET_AZURE_LOGS, data);
      }
    }
  },
  computed: {
    ...mapGetters([
      "azureSyncStatus",
      "azureMappingCalendarUsers",
      "azureMappingMeetingRooms",
      "azureMappingRelated",
      "periodList",
      "azureLogs"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
  },
  methods: {
    setSyncUser(meetingRoom) {
      const selectedUser = this.azureMappingRelated.find(item => {
        if (item.meeting_room_id === meetingRoom.id) return item;
      })
      if (selectedUser) return selectedUser.azure_user_id;
      return null;
    },
    onSync() {
      if (this.azureSyncStatus.actively === false) {
        this.$store.dispatch(GET_AZURE_AUTH)
            .then((res) => {
              window.location = res.url;
            });
      } else {
        this.$store.dispatch(PUT_AZURE_CANCEL_SYNC)
            .then(() => {
              this.$store.dispatch(GET_AZURE_SYNC_STATUS);
              this.$store.dispatch(GET_AZURE_MAPPING_DATA);
            });
      }
    },
    onUserChange(item, event) {
      const selectedUser = this.azureMappingRelated.find(i => {
        if (i.meeting_room_id === item.id) { return i }
      })

      if (selectedUser !== undefined || event === null) {
        for(let i = 0; i < this.azureMappingRelated.length; i++){
          if (this.azureMappingRelated[i] === selectedUser) {
            this.azureMappingRelated.splice(i, 1);
          }
        }
      }
      if (selectedUser === undefined && event !== null) {
        this.addedMappings.push({
          azure_user_id: event,
          meeting_room_id: item.id
        })
      }
    },
    onSaveSync() {
      this.addedMappings.push(...this.azureMappingRelated);
      this.$store.dispatch(POST_AZURE_MAPPING_DATA, { related: this.addedMappings })
          .then(() => {
            this.addedMappings = [];
            this.$store.dispatch(GET_AZURE_MAPPING_DATA);
          });
    },
    changePeriodDate(newDate) {
      this.filters.from = newDate.from;
      this.filters.to = newDate.to;
      this.filter_period = null;
    },
    changePageLimit(val) {
      let data = { ...this.filters, ...val };
      this.$store.dispatch(GET_AZURE_LOGS, data);
    },
    onSyncPage() {
      sendAmplitudeEvent('management-integrations-azure-sync show');
    },
    onLogsPage() {
      sendAmplitudeEvent('management-integrations-azure-logs show');
    },
  }
}
</script>

<style scoped>
  .azure-sync-meeting-room,
  .azure-logs-date {
    width: 160px;
  }
  .azure-sync-select {
    width: 50%;
  }
  .azure-logs-message {
    width: 650px;
  }
</style>
